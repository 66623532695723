<style lang="less" scoped>
.main {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: rgba(15, 17, 24, 1);
  &-body {
    position: relative;
    min-width: 996px;
    // max-width: 1920px;
    margin: 0 auto;
    &-wrapper-header {
      width: 100%;
      height: 100px;
      background: rgba(7, 9, 17, 0.6);
      position: fixed;
      top: 0px;
      z-index: 9;
    }
    &-header {
      width: 77.3%;
      margin-left: 11%;
      height: 100%;
      color: #dee6ff;
      display: flex;
      justify-content: space-between;
    }
  }
}
.main-header-left {
  line-height: 100px;
  span {
    color: #5577e0;
    font-size: 24px;
    margin-left: 15px;
    vertical-align: middle;
  }
  img {
    cursor: pointer;
    width: 55px;
    vertical-align: middle;
  }
}
.main-header-right {
  display: flex;
  line-height: 100px;
  &-item {
    width: 100px;
    height: 100%;
    text-align: center;
    line-height: 100px;
    color: #dee6ff;
    cursor: pointer;
    &:hover {
      color: #5577e0;
    }
  }
}
.button {
  width: 108px;
  height: 31px;
  color: #fff;
  margin-top: 10px;
  background: linear-gradient(
    214deg,
    rgba(85, 101, 251, 1) 0%,
    rgba(85, 153, 251, 1) 100%
  );
  text-align: center;
  line-height: 31px;
  border-radius: 16px;
  margin-top: 35px;
  cursor: pointer;
  &:hover {
    color: #dee6ff;
    box-shadow: 0px 4px 6px rgb(69, 123, 249);
  }
}
.actived {
  font-weight: bold;
  color: #5577e0;
}
.show-title {
  width: 120px;
  margin-left: -10px;
  background: rgba(27, 29, 40, 1);
  border-radius: 6px;
  &-children {
    font-size: 13px;
    width: 98px;
    margin-left: 11px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: #dee6ff;
    border-bottom: 1px solid #0f1118;
    &:hover {
      color: #5577e0;
    }
  }
}
</style>

<template>
  <div class="main">
    <div class="main-body">
      <div class="main-body-wrapper-header">
        <div class="main-body-header">
          <div class="main-header-left">
            <img src="../assets/logo.png"
                 alt="">
            <span> CECC </span>
          </div>
          <div class="main-header-right">
            <div class="main-header-right-item"
                 @click="back"
                 v-show="!showBack">
              &lt;--
            </div>
            <div class="main-header-right-item"
                 @click="changeLang">
              中/EN
            </div>
            <div class="main-header-right-item"
                 v-for="(item, index) in menu"
                 :class="index === activedIndex ? 'actived' : ''"
                 :key="index"
                 @click="routerTo(item)"
                 @mousemove="show = index"
                 @mouseleave="show = -1">
              <template v-if="!item.children">
                {{ $t(item.title) }}
              </template>
              <template v-else>
                <span>
                  {{ $t(item.title) }}
                </span>
                <div class="show-title">
                  <div v-show="show === index"
                       v-for="(itemChildren,indexx) in item.children"
                       :key="indexx"
                       @click="routerTo(itemChildren)"
                       class="show-title-children">
                    {{ $t(itemChildren.title) }}
                  </div>
                </div>
              </template>
            </div>
            <template>
              <div v-if="!this.userInfo"
                   class="main-header-right-item button"
                   @click="toLogin">
                {{$t('register')}}/{{$t('login')}}
              </div>
              <div v-else
                   class="main-header-right-item"
                   @click="routerTo({name: 'personCenter'})">
                {{$t('H6')}}
              </div>
            </template>
          </div>
        </div>
      </div>
      <router-view />
    </div>
  </div>
</template>
<script>

import { constantRouterMap } from '../router/index'

export default {
  name: 'home',
  data () {
    return {
      show: -1
    }
  },
  components: {
  },
  computed: {
    menu () {
      return this.$Cookies.get('cecc-lang') !== 'en' ? constantRouterMap[0].children.filter(item => item.showMenu) : constantRouterMap[1].children.filter(item => item.showMenu)
    },
    activedIndex () {
      let findIndex = this.menu.findIndex(item => this.$route.name && this.$route.name.includes(item.name));
      return findIndex;
    },
    userInfo () {
      return this.$store.state.user || this.$Cookies.get('cecc-user');
    },
    showBack () {
      return this.$route.name === 'home';
    }
  },
  created () {
    // if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    //   this.$router.push({
    //     name: 'mobile'
    //   })
    // }
  },
  mounted () {
  },
  methods: {
    toLogin () {
      this.$router.push({
        name: 'login'
      })
    },
    routerTo (router) {
      this.$router.push({
        name: router.name
      });
    },
    changeLang () {
      this.$Cookies.set('cecc-lang', this.$i18n.locale === 'en' ? 'zh' : 'en');
      let routeUrl = this.$router.resolve({
        name: this.$route.name,
      });
      window.open(routeUrl.href, '_blank');

      // this.$i18n.locale = this.$i18n.locale === 'en' ? 'zh' : 'en';
    },
    back () {
      this.$router.go(-1);
    }
  }
}
</script>
